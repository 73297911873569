import React ,{Component} from 'react'
import PropTypes from "prop-types"

class HtmlRenderer extends Component {
	componentDidMount(){
		let graphEl = this.props.htmlData;
		let htmlEl = document.getElementById(`${this.props.rendererID}`);
		htmlEl.innerHTML = graphEl;
	}
	render() {
		return(
			<div id={this.props.rendererID} className={this.props.rendererClass}></div>
		);
	}
}
HtmlRenderer.propTypes = {
	htmlData: PropTypes.string.isRequired,
	rendererID: PropTypes.string.isRequired,
	rendererClass: PropTypes.string
}
export default HtmlRenderer
